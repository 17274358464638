import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../middlewares/authContext";

import Home from "../images/navicon/home.png";
import About from "../images/navicon/about.png";
import Booking from "../images/navicon/book.png";
import Donate from "../images/navicon/festival.png";
import Festival from "../images/navicon/donate.png";
import Login from "../images/navicon/login.png";

class PublicNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData1: "",
      scrolled: false,
      showMobileMenu: false,
      submenu: "", // Track which submenu is open
    };
  }

  handleMobileMenu = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  handleCloseMenu = (resetSubmenu = false) => {
    this.setState({
      showMobileMenu: false,
      submenu: resetSubmenu ? "" : this.state.submenu,
    });
  };

  componentDidMount() {
    fetch("/api/get-user", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid token") {
          localStorage.removeItem("token");
        } else {
          this.setState({
            userData1: data.user,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (event.target.classList.contains("menu-nav-btn")) {
      return;
    }
    if (!this.menuContainer.contains(event.target)) {
      this.handleCloseMenu();
    }
  };

  handleLogout = async () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  static contextType = AuthContext;

  render() {
    const { isAuthenticated, user } = this.context;
    const { userData1, scrolled, submenu } = this.state; // Destructure submenu from state

    return (
      <>
        <button className="menu-nav-btn" onClick={this.handleMobileMenu}>
          <i
            onClick={this.handleMobileMenu}
            className="fa-duotone fa-solid fa-bars"
          ></i>
        </button>
        <div
          ref={(node) => {
            this.menuContainer = node;
          }}
          className={`navbar container ${scrolled ? "scrolled" : ""} ${
            this.state.showMobileMenu ? "mobileshow" : "mobilehide"
          }`}
        >
          <ul>
            <li>
              <Link to="/" onClick={this.handleCloseMenu}>
                Home
              </Link>
            </li>
            <li
              onMouseEnter={() => this.setState({ submenu: "about" })}
              onMouseLeave={() => this.setState({ submenu: "" })}
            >
              <Link to="/" onClick={this.handleCloseMenu}>
                About
              </Link>
              {submenu === "about" && (
                <div className="submenu large-add">
                  <Link
                    to="/about/iskcon-aravade"
                    onClick={this.handleCloseMenu}
                  >
                    ISKCON ARAVADE
                  </Link>
                  <Link to="/about/iskcon" onClick={this.handleCloseMenu}>
                    ISKCON
                  </Link>
                  <Link
                    to="/about/lokanath-swami-maharaj"
                    onClick={this.handleCloseMenu}
                  >
                    Lokanath Swami Maharaj
                  </Link>
                </div>
              )}
            </li>
            <li
              onMouseEnter={() => this.setState({ submenu: "festival" })}
              onMouseLeave={() => this.setState({ submenu: "" })}
            >
              <Link to="/" onClick={this.handleCloseMenu}>
                Festival
              </Link>
              {submenu === "festival" && (
                <div className="submenu">
                  <Link to="/festival/Calendar" onClick={this.handleCloseMenu}>
                    Calendar
                  </Link>
                  <Link to="/festival/all" onClick={this.handleCloseMenu}>
                    All Festival
                  </Link>
                </div>
              )}
            </li>
            <li
              onMouseEnter={() => this.setState({ submenu: "booking" })}
              onMouseLeave={() => this.setState({ submenu: "" })}
            >
              <Link to="/" onClick={this.handleCloseMenu}>
                Booking
              </Link>
              {submenu === "booking" && (
                <div className="submenu">
                  <Link to="/about/history" onClick={this.handleCloseMenu}>
                    Guest House
                  </Link>
                  <Link to="/about/team" onClick={this.handleCloseMenu}>
                    Vehicle{" "}
                  </Link>
                </div>
              )}
            </li>
            <li>
              <Link to="/" onClick={this.handleCloseMenu}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/donation" onClick={this.handleCloseMenu}>
                Donate <i class="fa-regular fa-box-heart"></i>
              </Link>
            </li>
            {isAuthenticated ? (
              <li>
                <Link
                  to="/login"
                  onClick={() => {
                    this.handleLogout();
                    this.handleCloseMenu();
                  }}
                >
                  Logout
                </Link>
              </li>
            ) : (
              <button className="btn btn-warning loginbtn">
                <Link to="/login" onClick={this.handleCloseMenu}>
                  Login <i className="fa-solid fa-user"></i>
                </Link>
              </button>
            )}
            {user.role !== "Anonymous" && userData1 !== undefined ? (
              <li>
                <img
                  className="nav-profile"
                  src={userData1.profilePic}
                  alt="User Profile"
                />
              </li>
            ) : null}
          </ul>
        </div>
        <div className="navbar-second">
          <ul className="mobile-nav">
            <li>
              <Link to="/" onClick={this.handleCloseMenu}>
                <img loading="lazy"src={Home} alt="Home" />
                Home
              </Link>
            </li>
            {submenu === "about" && (
              <div className="submenu-mobile">
                <Link to="/about/iskcon-aravade" onClick={this.handleCloseMenu}>
                  ISKCON ARAVADE
                </Link>
                <Link to="/about/iskcon" onClick={this.handleCloseMenu}>
                  ISKCON
                </Link>
                <Link
                  to="/about/lokanath-swami-maharaj"
                  onClick={this.handleCloseMenu}
                >
                  Lokanath Swami Maharaj
                </Link>
              </div>
            )}
            <li>
              <span
                onClick={() =>
                  this.setState((prevState) => ({
                    submenu: prevState.submenu === "about" ? "" : "about",
                  }))
                }
              >
                <img loading="lazy"src={About} alt="About" />
                About
              </span>
            </li>
            
            <li>
              <Link to="/donation" onClick={this.handleCloseMenu}
                
              >
                <img loading="lazy"src={Festival} alt="Festival" />
                Donate
              </Link>
            </li>
            {submenu === "festival" && (
              <div className="submenu-mobile">
                <Link to="/festival/Calendar" onClick={this.handleCloseMenu}>
                  Calender
                </Link>
                <Link to="/festival/all" onClick={this.handleCloseMenu}>
                  All Events
                </Link>
              </div>
            )}
            <li>
              <span onClick={() =>
                  this.setState((prevState) => ({
                    submenu: prevState.submenu === "festival" ? "" : "festival",
                  }))
                }>
                <img loading="lazy"src={Donate} alt="Festival" />
                Festival's
              </span>
            </li>
            <li>
              <Link to="/login" onClick={this.handleCloseMenu}>
                <img loading="lazy"src={Login} alt="Login" />
                Login
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default PublicNav;
